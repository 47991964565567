<template>
  <h4 class="latest-news-category-slot__wrapper">
    <a-link :to="to">
      {{ article.Category.Name }}
    </a-link>
  </h4>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'

export default {
  name: 'HomepageLatestNewsCategorySlot',
  mixins: [mixins.urlGenerators],
  props: {
    article: propValidator([PROP_TYPES.OBJECT])
  },
  computed: {
    to() {
      return this.generateCategoryPath(this.article)
    }
  }
}
</script>

<style scoped lang="scss">
.latest-news-category-slot__wrapper {
  font-size: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include mobile {
    font-size: 20px;
    line-height: 33px;
  }
}
</style>
