<template>
  <section class="homepage-latest-news__wrapper">
    <a-section-title title="Latest News" link="all-news" delimiter />

    <a-article-list
      :articles="articles"
      id-field="Id"
      class="homepage-latest-news__article-list"
    >
      <template v-slot="{ article, position }">
        <template v-for="breakpoint in $breakpoint">
          <a-visibility show :on="[breakpoint]" :key="breakpoint">
            <a-combined-component
              :grid-content-settings="$options.consts.gridContentSettings"
              :breakpoint="breakpoint"
              :data="article"
              :position="position"
              class="homepage-latest-news__grid-item"
            >
              <template #category>
                <category-slot
                  :article="article"
                  class="homepage-latest-news__category-name"
                />
              </template>
              <template #articleSlot="{ data }">
                <article-slot
                  v-if="data.article"
                  :article="data.article"
                  :show-media="data.showMedia"
                  :topic-line-count="data.topicLineCount"
                  :fixed-height="data.fixedHeight"
                  class="homepage-latest-news__article-slot"
                />
              </template>
              <template #banner>
                <banner-slot class="homepage-latest-news__banner-wrapper" />
              </template>
            </a-combined-component>
          </a-visibility>
        </template>
      </template>
    </a-article-list>
  </section>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import ASectionTitle from '@/components/_homepage/Shared/ASectionTitle'
import AArticleList from 'shared/AArticleList'
import { gridContentSettings } from './enums'
import CategorySlot from './CategorySlot'
import ArticleSlot from 'shared/ArticleSlots/Fm/Homepage/AArticleSlotLatestNews'
import BannerSlot from './BannerSlot'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'HomepageLatestNews',
  mixins: [serverCacheKey],
  components: {
    AArticleList,
    ASectionTitle,
    ACombinedComponent: hydrateWhenVisible(
      () => import('@/components/_homepage/Shared/ACombinedComponent'),
      {
        props: ['grid-content-settings', 'breakpoint', 'data', 'position']
      }
    ),
    CategorySlot,
    ArticleSlot,
    BannerSlot
  },
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  },
  consts: {
    gridContentSettings
  }
}
</script>
<style scoped lang="scss">
@import '~assets/scss/local/mixins/homepage-latest-news';
@import '~assets/scss/local/mixins/grid';

$maxGridChildCount: 5;

.homepage-latest-news__wrapper {
  $grid-setup: (
    $columnSetup: (
      $above-desktop-xl: repeat(5, minmax(0, 100%)),
      $desktop-lg: repeat(3, minmax(0, 100%)),
      $tablet: repeat(2, minmax(0, 100%)),
      $mobile: repeat(1, minmax(0, 100%))
    ),
    $rowSetup: (
      $above-desktop-xl: minmax(0, auto),
      $desktop-lg: repeat(2, minmax(0, auto)),
      $tablet: repeat(3, minmax(0, auto)),
      $mobile: repeat(3, minmax(0, auto))
    ),
    $columnGapSetup: (
      $above-desktop-xl: 25px,
      $desktop-xl: 10px,
      $desktop-lg: 40px,
      $desktop-md: 62px,
      $tablet: 20px
    ),
    $rowGapSetup: (
      $above-desktop-xl: 25px
    ),
    $combinedCellsSetup: (
      2: (
        $desktop-md: #{1} / #{2} / #{3} / #{3},
        $tablet: auto / auto / auto / auto
      ),
      3: (
        $desktop-lg: #{1} / #{3} / #{3} / #{4},
        $desktop-md: auto / auto / auto / auto
      ),
      4: (
        $tablet: #{2} / #{2} / #{4} / #{3},
        $mobile: auto / auto / auto / auto
      )
    )
  );

  .homepage-latest-news__article-list {
    @include gridBuilder($grid-setup);

    .homepage-latest-news__grid-item {
      width: 100%;
    }
  }

  .homepage-latest-news__category-name {
    line-height: $category-name-line-height;
  }

  .homepage-latest-news__article-slot {
    margin-bottom: $article-slot-bottom-margin;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /deep/ .latest-news-article-slot__featured-media {
    margin-top: $featured-media-margin-top;
  }

  .homepage-latest-news__banner-wrapper {
    position: relative;

    @include desktop-lg {
      margin-top: $banner-margin-top;
    }

    @include mobile {
      margin-top: 10px;
    }
  }
}
</style>
