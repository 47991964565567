import scss from '@/utils/scss'
import {
  a,
  am,
  b,
  c
} from '@/components/_homepage/Shared/ACombinedComponent/helpers'

const { breakpoints } = scss

/**
 * c()                              - Category Name
 * a(articleIndex, topicLineCount)  - Article slot without media.
 * am(articleIndex, topicLineCount) - Article slot with media.
 * b()                              - Banner
 */
export const gridContentSettings = {
  [breakpoints.aboveDesktopXl]: [
    [c(), am(0, 3, true), a(1, 3, true)],
    [c(), am(0, 3, true), a(1, 3, true)],
    [c(), am(0, 3, true), a(1, 3, true)],
    [c(), am(0, 3, true), a(1, 3, true)],
    [c(), am(0, 3, true), a(1, 3, true)]
  ],
  [breakpoints.desktopXl]: [
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)]
  ],
  [breakpoints.desktopLg]: [
    [c(), am(0, 3, true)],
    [c(), am(0, 3, true)],
    [c(), am(0, 3, true), b(), a(1, 3, true)],
    [c(), am(0, 2, true), a(1, 3, true)],
    [c(), am(0, 2, true), a(1, 3, true)]
  ],
  [breakpoints.desktopMd]: [
    [c(), am(0, 4, true)],
    [c(), am(0, 4, true), b(), a(1, 4, true)],
    [c(), am(0, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)]
  ],
  [breakpoints.desktopSm]: [
    [c(), am(0, 4, true)],
    [c(), am(0, 4, true), b(), a(1, 4, true)],
    [c(), am(0, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)],
    [c(), am(0, 4, true), a(1, 4, true)]
  ],
  [breakpoints.tablet]: [
    [c(), am(0, 2, true)],
    [c(), am(0, 2, true)],
    [c(), am(0, 2, true)],
    [c(), am(0, 2, true), b(), a(1, 2, true), a(2, 2, true)],
    [c(), am(0, 2, true), a(1, 2, true), a(2, 2, true)]
  ],
  [breakpoints.mobile]: [
    [c(), am(0, 3, false)],
    [c(), am(0, 3, false), b()],
    [c(), am(0, 3, false)],
    [c(), am(0, 3, false)],
    [c(), am(0, 3, false)]
  ]
}
