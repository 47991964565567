<template>
  <div class="latest-news-article-slot__wrapper">
    <a-media
      v-if="showMedia"
      :image="article.featuredImage"
      :alt="article.title"
      :image-aspect-ratio="$options.consts.aspectRatios.article"
      :video="article.featuredVideo"
      :video-aspect-ratio="$options.consts.aspectRatios.article"
      :url="article.canonicalUrl"
      viewport-optimization
      :srcset-location="$options.consts.srcsetLocation"
      :rounded="true"
      class="latest-news-article-slot__featured-media"
    />
    <a-dots
      :text="article.title"
      :row-count="topicLineCount"
      :row-height="20"
      tag="h4"
      :fixed-height="fixedHeight"
      :link-props="{ to: article.canonicalUrl }"
      class="latest-news-article-slot__title"
    />
    <time class="latest-news-article-slot__timestamp">
      {{ publishDate }}
    </time>
    <a-visibility hide :on="[$breakpoint.mobile]">
      <i class="latest-news-article-slot__delimiter" />
    </a-visibility>
  </div>
</template>

<script>
import { prop } from 'ramda'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import AMedia from 'shared/AMedia'
import { aspectRatios } from 'enums/aspectRatios'
import { SRCSET_LOCATION } from '@/utils/enums/images'
import ADots from 'shared/ADots'

export default {
  name: 'AArticleSlotLatestNews',
  components: { ADots, AMedia },
  props: {
    article: propValidator([PROP_TYPES.OBJECT]),
    showMedia: propValidator([PROP_TYPES.BOOLEAN], false, false),
    topicLineCount: propValidator([PROP_TYPES.NUMBER]),
    fixedHeight: propValidator([PROP_TYPES.BOOLEAN])
  },
  consts: {
    aspectRatios,
    srcsetLocation: SRCSET_LOCATION.HOMEPAGE_LATEST_NEWS
  },
  computed: {
    publishDate() {
      return this.$helper.formatDate.toDefault(this.article.publishedOn)
    }
  },
  methods: { prop }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/homepage-latest-news';
@import '~assets/scss/global/mixins/figures';

.latest-news-article-slot__wrapper {
  .latest-news-article-slot__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  .latest-news-article-slot__featured-media {
    margin-top: $featured-media-margin-top;
    margin-bottom: $featured-media-margin-bottom;
  }

  .latest-news-article-slot__timestamp {
    display: block;
    margin-top: $timestamp-margin-top;
    font-size: 12px;
    line-height: $timestamp-line-height;
  }

  .latest-news-article-slot__delimiter {
    @include delimiter($mt: 13px);
  }
}
</style>
